<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="stimul-reg-modal"
  no-click-animation
  persistent
  transition="dialog-bottom-transition"
  width="100%"
)
  ui-icon.close-btn(name="close" @click="cancel")
  h2.t-h2.mb-6 Войдите, чтобы открыть все возможности Portal DA
  .bullet-list.mb-8
    .t-normal-bold.mb-3 Вы сможете:
    .bullet-list__item.bullet-list__item--checked.t-middle Смотреть скрытую информацию;
    .bullet-list__item.bullet-list__item--checked.t-middle Сохранять активы и поиски;
    .bullet-list__item.bullet-list__item--checked.t-middle Участвовать в аукционах;
    .bullet-list__item.bullet-list__item--checked.t-middle Получать бесплатную консультацию.
  ui-btn.mb-5(large primary @click="confirm") Войти или зарегистрироваться
  .stimul-reg-modal__logotips
    .stimul-reg-modal__logotips-sber.text--small Сбер ID и Сбербизнес ID
    .stimul-reg-modal__logotips-esia.text--small Госуслуги
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';

  export default {
    components: { UiBtn, UiIcon },

    data() {
      return {
        isActive: false,
      };
    },

    methods: {
      open(options) {
        options = options || {};
        this.options = { ...this.options, ...options };
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        this.resolve(true);
        this.isActive = false;
        this.$cookies.set('stimul-popup', 1, '365d');
        this.$router.push(this.$path.signIn());
      },

      cancel() {
        this.resolve(false);
        this.isActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .stimul-reg-modal {
    &.v-dialog {
      margin: 0 16px;
      padding: 110px 24px 35px;
      background: url('~@/assets/message-approved.svg') no-repeat white;
      background-size: 120px;
      position: relative;
    }

    .close-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 25px;
      color: c('black70', 'light');
    }

    &__logotips {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;

      &-sber,
      &-esia {
        position: relative;

        &::before {
          background: url('~@/assets/logos/sberbank-logo-22.svg') no-repeat center / contain;
          content: '';
          position: absolute;
          top: 4px;
          left: -20px;
          width: 16px;
          height: 16px;
        }
      }
      &-esia::before {
        background: url('~@/assets/logos/esia-logo.svg') no-repeat center / contain;
      }
    }
  }
</style>
