<template lang="pug">
.new-year-logo(:style="styles")
</template>

<script>
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    name: 'new-year-logo',
    mixins: [measurable],

    computed: {
      styles() {
        return {
          ...this.measurableStyles,
        };
      },
    },
  };
</script>

<style lang="scss">
  .new-year-logo {
    background: url('~@/assets/new-year-logo.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: hidden;
    width: 140px;
    height: 46px;
  }
</style>
