<template lang="pug">
.get-app-banner
  .get-app-banner__logo
  .get-app-banner__content
    .get-app-banner__title
      .t-small-bold.mr-2 Portal DA
      <svg width="50" height="10" viewBox="0 0 50 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0L6.32252 3.17971L9.75528 3.45492L7.13988 5.69529L7.93893 9.04508L5 7.25L2.06107 9.04508L2.86012 5.69529L0.244718 3.45492L3.67748 3.17971L5 0Z" fill="#FEC062"/>
        <path d="M15 0L16.3225 3.17971L19.7553 3.45492L17.1399 5.69529L17.9389 9.04508L15 7.25L12.0611 9.04508L12.8601 5.69529L10.2447 3.45492L13.6775 3.17971L15 0Z" fill="#FEC062"/>
        <path d="M25 0L26.3225 3.17971L29.7553 3.45492L27.1399 5.69529L27.9389 9.04508L25 7.25L22.0611 9.04508L22.8601 5.69529L20.2447 3.45492L23.6775 3.17971L25 0Z" fill="#FEC062"/>
        <path d="M35 0L36.3225 3.17971L39.7553 3.45492L37.1399 5.69529L37.9389 9.04508L35 7.25L32.0611 9.04508L32.8601 5.69529L30.2447 3.45492L33.6775 3.17971L35 0Z" fill="#FEC062"/>
        <mask id="mask0_1286_35158" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="40" y="0" width="10" height="10">
        <path d="M45 0L46.3225 3.17971L49.7553 3.45492L47.1399 5.69529L47.9389 9.04508L45 7.25L42.0611 9.04508L42.8601 5.69529L40.2447 3.45492L43.6775 3.17971L45 0Z" fill="#E6E6E6"/>
        </mask>
        <g mask="url(#mask0_1286_35158)">
        <path d="M45 0.65099L46.0917 3.27572L46.1503 3.41671L46.3025 3.42891L49.1362 3.65608L46.9772 5.50542L46.8613 5.60476L46.8967 5.75329L47.5563 8.51842L45.1303 7.03665L45 6.95705L44.8697 7.03665L42.4437 8.51842L43.1033 5.75329L43.1387 5.60476L43.0228 5.50542L40.8638 3.65608L43.6975 3.42891L43.8497 3.41671L43.9083 3.27572L45 0.65099Z" stroke="#FEC062" stroke-width="0.5"/>
        <path d="M44.9999 7.25V0L43.6774 3.17971L40.2446 3.45491L42.86 5.69529L42.061 9.04508L44.9999 7.25Z" fill="#FEC062"/>
        </g>
      </svg>
    .t-caption-small.c-black60 Открыть в приложении

  .get-app-banner__actions
    .get-app-black-btn.my-1.mr-3(@click="redirectToStore") Открыть
    ui-icon(name="close-2" @click="$emit('close')")
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit/';

  export default {
    name: 'get-app-banner',
    components: { UiBtn, UiIcon },

    computed: {
      userAgent() {
        return navigator.userAgent || navigator.vendor || window.opera;
      },

      isIos() {
        return /iPad|iPhone|iPod/.test(this.userAgent) && !window.MSStream;
      },

      isAndroid() {
        return /android/i.test(this.userAgent);
      },
    },

    methods: {
      redirectToStore() {
        let url = null;

        if (this.isIos) {
          url = this.$path.getAppApple();
        }
        if (this.isAndroid) {
          url = this.$path.getAppRustore();
        }

        if (url) window.location = url;
      },
    },
  };
</script>

<style lang="scss">
  .get-app-banner {
    z-index: 10;
    width: 100%;
    background-color: c('white', 'light');
    height: 48px;
    display: flex;
    padding: 6px 0;

    &__logo {
      @include svg-bg-colored('pda-app-icon', 'assets');
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }

    &__content {
      flex-grow: 1;
    }

    &__title {
      display: flex;
      align-items: center;
    }

    &__actions {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      margin-left: 8px;
    }

    .get-app-black-btn {
      @include text-style('button-small', 'light');

      background-color: c('black', 'light');
      color: c('white', 'light');
      padding: 6px 16px;
      border-radius: 16px;
    }
  }
</style>
