<template lang="pug">
nav.get-apps(:class="{ 'get-apps--centered': centered }")
  .get-apps__title.t-caption-xsmall Скачайте приложение
  .get-apps__buttons
    a.get-apps__item.get-apps__item--appstore(:href="$path.getAppApple()")
    a.get-apps__item.get-apps__item--rustore(:href="$path.getAppRustore()")
</template>

<script>
  export default {
    name: 'get-apps',
    props: {
      centered: { type: Boolean, default: false },
    },
  };
</script>

<style lang="scss">
  .get-apps {
    &--centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .get-apps__title {
      color: c('black80', 'light');
      margin-bottom: 8px;
    }

    .get-apps__buttons {
      display: flex;
    }

    .get-apps__item {
      display: block;
      width: 120px;
      height: 40px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .get-apps__item--appstore {
      @include svg-bg-colored('appstore-black', 'assets');
    }

    .get-apps__item--rustore {
      @include svg-bg-colored('rustore-logo', 'assets');
      width: 120px;
      height: 40px;
      background-size: 90%;
      border: 1px solid #000;
      border-radius: 6px;
    }
  }
</style>
