<template lang="pug">
.user-panel(:class="{ 'user-panel--signed-in': currentUser }")
  template(v-if="currentUser")
    router-link.user-panel__wrap(:to="$path.profile()")
      .d-flex.justify-space-between
        .t-caption-xsmall.c-gray70 Личный кабинет

        .user-badge(:class="userIsPro ? 'user-badge--pro' : 'user-badge--basic'")
          | {{ userIsPro ? "PRO" : "Basic" }}

      .d-flex.justify-space-between.align-end
        .user-panel__name
          .t-h5 {{ currentUser.name.split(" ")[1] }} {{ currentUser.name.split(" ")[2] }}
          .t-h5.font-weight-light {{ currentUser.name.split(" ")[0] }}

        ui-icon(name="arrow-outline-right")

  template(v-else)
    ui-btn(primary :href="$path.signIn()") Войти
    .t-middle.mt-3 Вы сможете связываться с продавцами и участвовать в аукционах
</template>

<script>
  import { UiIcon, UiBtn, UiBadge } from '@/uikit';
  import { mapGetters } from 'vuex';

  export default {
    name: 'user-panel',
    components: { UiIcon, UiBtn, UiBadge },

    computed: {
      ...mapGetters(['currentUser']),

      userIsPro() {
        return this.currentUser.status == 'pro';
      },
    },

    methods: {
      signOut() {
        this.$emit('signout');
      },
    },
  };
</script>

<style lang="scss">
  .user-panel {
    padding: 24px 32px 24px;
    background-color: c('gray5', 'light');
    border-radius: 12px;
  }

  .user-panel--signed-in {
    padding: 10px 8px 12px 32px;
  }

  .user-panel__wrap {
    text-decoration: none;
    color: #25282b !important;
  }

  .user-status-badge {
    display: inline-flex;
    @include font-size(14, 16, 500);
    color: c('white', 'light');
    padding: 4px 8px;
    border-radius: 12px;

    &--pro {
      background-color: c('primary--light', 'light');
    }

    &--basic {
      background-color: c('black60', 'light');
    }
  }

  .user-badge {
    position: relative;
    display: inline-block;
    padding: 5px 8px;
    color: white;
    @include font-size(14, 16, 500);
    text-align: center;
    border-radius: 12px;
    box-shadow: shadow('xsmall', 'light');

    &--basic {
      background-color: c('gray60', 'light');
    }

    &--pro {
      padding-left: 25px;
      background-color: c('primary40', 'light');

      &::before {
        position: absolute;
        top: 48%;
        left: 8px;
        width: 14px;
        height: 14px;
        transform: translateY(-50%);
        content: '';
        @include svg-bg-mask-recolored('star', c('white', 'light'), light);
      }
    }
  }

  .user-status-icon {
    background-size: cover;
    width: 60px;
  }
</style>
