export const schema = {
  baseUrl: process.env.VUE_APP_API_HOST + '/api/v2',
  api: {
    notifications_list: {
      request: {
        method: 'get',
        url: '/notifications.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    notification_toggle_read_by_id: {
      request: {
        method: 'get',
        url: '/notifications/(:id)/toggle.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    notifications_read_all: {
      request: {
        method: 'get',
        url: '/notifications/read_all.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },

    toggle_notification: {
      request: {
        method: 'patch',
        url: '/profile/notifications/toggle.json',
        responseType: 'json',
      },
      response: {
        plain: true,
      },
    },
  },
};

export default schema;
