<template lang="pug">
.stimul-reg-popup(v-if="showPopup")
  ui-btn.close(icon plain @click="close")
    ui-icon(name="close")
  .t-normal.mb-3 Зарегистрируйтесь или войдите, чтобы открыть все возможности Portal DA!
  ui-btn(secondary @click="open") Подробнее
  stimul-reg-modal(ref="stimulRegModal")
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';
  import StimulRegModal from '@/components/stimul-reg/StimulRegModal.vue';

  export default {
    name: 'stimul-reg-banner',
    components: { UiIcon, UiBtn, StimulRegModal },

    data() {
      return {
        showPopup: false,
        showModal: false,
      };
    },

    mounted() {
      if (!this.$cookies.get('stimul-popup') || this.$cookies.get('stimul-popup') === '0') {
        this.$cookies.set('stimul-popup', 0, '365d');
        this.showPopup = true;
      }
    },

    methods: {
      open() {
        this.$refs.stimulRegModal.open();
      },
      close() {
        this.showPopup = false;
        this.showModal = false;
        this.$cookies.set('stimul-popup', 1, '365d');
      },
    },
  };
</script>
<style lang="scss">
  .stimul-reg-popup {
    margin: 0 16px;
    padding: 24px 45px 24px 90px;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
    border-radius: 4px;
    border: 1px solid c('primary', 'light');
    background: url('~@/assets/message-approved.svg') no-repeat white;
    background-size: 90px;
    position: absolute;
    right: 0;
    top: 48px;

    &::before {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      background: white;
      border-top-left-radius: 4px;
      border-left: 1px solid c('primary', 'light');
      border-top: 1px solid c('primary', 'light');
      right: 25px;
      top: -8px;
    }

    .close {
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
</style>
