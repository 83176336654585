<template lang="pug">
// это на новый год - .app-header__new-year

v-app-bar.app-header(:class="{ 'v-toolbar__noshadow-page': noShadowPage }" app height=48)
  ui-icon(name="menu" @click="$emit('nav:click')")
  v-spacer
  a.pos-center(:href="$path.home()")
    portal-da-logo
    //- new-year-logo
  v-spacer
  template(v-if="!noButtons")
    template(v-if="currentUser")
      ui-btn.app-header__notifications-btn.px-2.mr-2(text :to="$path.notifications()")
        v-badge(
          :value="unreadNotifications > 0"
          color="primary"
          dot
          offset-x=4
          offset-y=4
        )
          ui-icon.c-black70(name="bell")
      ui-btn.app-header__interactions-btn.px-2(text :to="$path.interactions()")
        ui-icon.c-black70(name="message2")
    template(v-else)
      ui-btn.app-header__auth-btn(text :to="$path.signIn()")
        ui-icon.c-primary(name="enter" left)
        | Войти
  template(v-if="showBanner && this.$route.path === '/'" #extension)
    get-app-banner.app-header__banner(@close="hideGetAppBanner")
  stimul-reg-popup(v-if="!currentUser && !onSignPage")
</template>

<script>
  import { mapGetters } from 'vuex';
  import { UiBtn, UiIcon } from '@/uikit';
  import PortalDaLogo from '@/components/PortalDaLogo.vue';
  import NewYearLogo from '@/components/NewYearLogo.vue';
  import StimulRegPopup from '@/components/stimul-reg/StimulRegPopup.vue';
  import GetAppBanner from '@/components/app/GetAppBanner.vue';
  import { service as API } from '@/services/notifications';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageNotifications', 'blue');

  const NOTIFICATION_UPDATE_DELAY = 1 * 60 * 1000; // каждые 60 cек

  export default {
    name: 'app-header',
    components: { PortalDaLogo, UiBtn, UiIcon, GetAppBanner, StimulRegPopup, NewYearLogo },

    props: {
      noButtons: { type: Boolean, default: false },
    },

    data() {
      return {
        showBanner: false,
        loading: false,
        unreadNotifications: 0,
        notificationUpdateTimer: undefined,
      };
    },

    computed: {
      onSignPage() {
        return this.$route.name === 'pageSignUp' || this.$route.name === 'pageSignIn';
      },

      noShadowPage() {
        const flatHeaderPages = [ 'pageHome', 'pageCatalog', 'pageAsset' ];
        const route = this.$route.name;

        return flatHeaderPages.includes(route);
      },
      ...mapGetters(['currentUser']),
    },

    created() {
      try {
        this.showBanner = !sessionStorage.getItem('hideGetAppBanner');
      } catch (error) {
        logger.log(error);
      }
    },

    mounted() {
      this.fetchNotifications();

      this.notificationUpdateTimer = setInterval(() => {
        this.fetchNotifications();
      }, NOTIFICATION_UPDATE_DELAY);
    },

    beforeDestroy() {
      clearInterval(this.notificationUpdateTimer);
    },

    methods: {
      async fetchNotifications() {
        if (!this.currentUser) return;

        try {
          this.loading = true;
          const { count } = await API.getNotifications();
          this.unreadNotifications = count;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      hideGetAppBanner() {
        try {
          sessionStorage.setItem('hideGetAppBanner', true);
          this.showBanner = false;
        } catch (error) {
          logger.log(error);
        }
      },
    },
  };
</script>

<style lang="scss">
  .app-header {
    background-color: c('white', 'light') !important;
    box-shadow: shadow('xsmall', 'light') !important;

    &__new-year {
      background-image: url('~@/assets/snowflakes.png');
      background-size: contain;
    }

    .v-toolbar__content {
      z-index: 2;
    }

    &.v-toolbar__noshadow-page {
      box-shadow: none !important;
      border-bottom: 1px solid c('gray10', 'light') !important;
    
      &.v-app-bar--is-scrolled {
        box-shadow: shadow('xsmall', 'light') !important;
        border-bottom: none !important;
      }
    }
  }

  .app-header__auth-btn {
    padding: 0 !important;
  }

  .pos-center {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .app-header__banner {
    border-top: 1px solid c('black10', 'light');
  }
</style>
