<template lang="pug">
.portal-da-logo(:style="styles")
</template>

<script>
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    name: 'portal-da-logo',
    mixins: [measurable],

    computed: {
      styles() {
        return {
          ...this.measurableStyles,
        };
      },
    },
  };
</script>

<style lang="scss">
  .portal-da-logo {
    @include svg-bg-colored('portal-da-logo-gb', 'assets');
    width: 117px;
    height: 44px;
  }
</style>
