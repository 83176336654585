import { BaseApi, ApiSchema, AxiosProvider, nestedParamsSerializer } from '@/libs/api';
import { schema } from './schema';

const apiSchema = new ApiSchema(schema);
const axiosProvider = new AxiosProvider({
  baseUrl: apiSchema.getBaseUrl(),
  paramsSerializer: nestedParamsSerializer,
});

class NotificationsService extends BaseApi {
  async getNotifications() {
    const {
      data: { notifications, count },
    } = await this.fetch('notifications_list');
    return { notifications, count };
  }

  async toggleRead(id) {
    const { ok } = await this.fetch('notification_toggle_read_by_id', null, {
      id: id,
    });
    return ok;
  }

  async markAllRead() {
    const {
      data: { read_all },
    } = await this.fetch('notifications_read_all', null);
    return read_all;
  }

  async toggleNotification(type, channel) {
    const {
      data: { enabled },
    } = await this.fetch('toggle_notification', { channel, type });
    return enabled;
  }
}

const service = new NotificationsService(apiSchema, axiosProvider);

export { NotificationsService };
export { service };
export default service;
